import React, { useState } from 'react';
import { Map } from 'mapbox-gl';
import { MapOptions, MapOptionsAction } from 'page-explore';
import ModePicker from '../../common/mode-picker';
import { OVERLAY } from '../../common/overlay';
import "/opt/build/repo/src/page-explore/page-heatmaps/routes/index.tsx?resplendence=true";
import cx from 'classnames';
import {
    defineMessages,
    FormattedMessage,
    FormattedNumber,
    useIntl
} from 'react-intl';
import { DateRangeSelect } from 'common/date-picker';
import { LocalDate } from 'utils/date-tools';
import { useMapView } from 'common/use-map-view';
import TotalCount from '../../common/total-count';
import RoutesSidebar from './sidebar';
import MultiHourRangeSelector from 'common/multi-hour-range-slider';
import HeatmapKey from '../../common/heatmap-key';
import { VehicleClass } from 'graphql.g';
import { TabToggle } from 'common/layout';
import { findAverageDailyTrips } from '../../layers/routes/popup';
import RoutesPrivacyCallout from '../privacy-callout';
import {
    useVehicleClasses,
    VehicleClassQueryStatePicker
} from 'common/vehicle-classes';
import useDateRangeQuery from 'utils/use-date-range-query';
import { useRoutesDates } from '../../layers/routes/api';
import { useRouteEffects } from './effects';
import { AggregatedRouteFeature } from '../../layers/routes/sources';
import { useReducer } from 'react';
import { routesInitialState, routesReducer } from './reducer';
import { SOURCE_NAMES } from 'page-explore/common/constants';

/*
@import 'style.scss';
*/;

export const SELECT_WRAPPER = "rx-page-explore-page-heatmaps-routes-index-1"/*
    display: grid;
*/;

interface MapRoutesProps {
    map: Map | null;
    mapOptions: MapOptions;
    dispatchMapOptions: React.Dispatch<MapOptionsAction>;
}

type CountDisplayFormat = 'Total Count' | 'Avg. Trips';

const messages = defineMessages({
    'total-count': 'Total Count',
    'avg-trips': 'Avg. Trips'
});

const MapRoutes = ({ mapOptions, map, dispatchMapOptions }: MapRoutesProps) => {
    const [routesState, routesDispatch] = useReducer(
        routesReducer,
        routesInitialState
    );

    const { ianaTimezone } = useMapView();
    const intl = useIntl();
    // Routes doesn't support "other" type vehicles, so we'll exclude it from the
    // vehicle class selector if it's available.
    const availableVehicleClasses = useVehicleClasses()?.filter(
        vc => vc !== VehicleClass.other
    );

    const [countDisplayFormat, setCountDisplayFormat] = useState<
        CountDisplayFormat
    >('Total Count');

    const [earliestAllowed, latestAllowed] = useRoutesDates();
    const lastDateAvailable = latestAllowed
        ? LocalDate.fromDateString(latestAllowed)
        : // If graphql does not return the last date available,
          // default to two days prior to account for 36 hour data ingestion gap.
          LocalDate.todayInTz(ianaTimezone).minusDays(2);
    const [dataPeriod, setDataPeriod] = useDateRangeQuery([
        lastDateAvailable.minusDays(90),
        lastDateAvailable
    ]);
    const [selectedHours, setSelectedHours] = useState<number[] | null>(null);

    const effects = useRouteEffects(
        map,
        dispatchMapOptions,
        routesState,
        routesDispatch,
        {
            dataPeriod,
            hours: selectedHours
        }
    );

    const matchedTripCount = routesState.routes.matchedTripCount;
    const avgMatchedTripCount =
        matchedTripCount && findAverageDailyTrips(dataPeriod, matchedTripCount);

    const legendScale = effects.dataSteps
        ? effects.dataSteps.map(({ value, color }) => ({
              value: (
                  <FormattedNumber
                      value={
                          countDisplayFormat === 'Total Count'
                              ? value
                              : findAverageDailyTrips(dataPeriod, value)
                      }
                  />
              ),
              color
          }))
        : null;

    return (
        <>
            <div className={cx(OVERLAY)}>
                <ModePicker
                    dispatchMapOptions={dispatchMapOptions}
                    options={mapOptions}
                >
                    {!!availableVehicleClasses?.length && (
                        <div className={SELECT_WRAPPER}>
                            <label>
                                <FormattedMessage
                                    key="select-vehicle-type-label"
                                    defaultMessage="Vehicle Type"
                                />
                            </label>
                            <VehicleClassQueryStatePicker
                                vehicleClasses={availableVehicleClasses}
                            />
                        </div>
                    )}
                    <div className={SELECT_WRAPPER}>
                        <label>
                            <FormattedMessage
                                key="select-date-label"
                                defaultMessage="Data Period"
                            />
                        </label>
                        <DateRangeSelect
                            selectedDateRange={dataPeriod}
                            onChange={value => setDataPeriod(value)}
                            latestAllowed={
                                latestAllowed
                                    ? LocalDate.fromDateString(latestAllowed)
                                    : undefined
                            }
                            earliestAllowed={
                                earliestAllowed
                                    ? LocalDate.fromDateString(earliestAllowed)
                                    : undefined
                            }
                        />
                    </div>

                    <MultiHourRangeSelector setHours={setSelectedHours} />

                    <TabToggle
                        label={
                            <FormattedMessage
                                key="select-count-type-label"
                                defaultMessage="Count Type"
                            />
                        }
                        tabLabels={[
                            intl.formatMessage(messages['total-count']),
                            intl.formatMessage(messages['avg-trips'])
                        ]}
                        checked={countDisplayFormat === 'Avg. Trips'}
                        onChange={value => {
                            setCountDisplayFormat(
                                value ? 'Avg. Trips' : 'Total Count'
                            );
                        }}
                    />

                    <TotalCount
                        count={
                            countDisplayFormat === 'Total Count'
                                ? matchedTripCount
                                : avgMatchedTripCount
                        }
                    >
                        {countDisplayFormat === 'Total Count' ? (
                            <FormattedMessage
                                key="matched-trips"
                                defaultMessage="Matched Trips"
                            />
                        ) : (
                            <FormattedMessage
                                key="daily-average-trips"
                                defaultMessage="Daily Avg. Trips"
                            />
                        )}
                    </TotalCount>
                </ModePicker>
                <RoutesPrivacyCallout />
                <RoutesSidebar
                    mapOptions={mapOptions}
                    dispatchMapOptions={dispatchMapOptions}
                    dateRange={dataPeriod}
                    routesGeoJSONData={
                        // mapbox & typescript aren't playing nice
                        (effects.sources[SOURCE_NAMES.AGGREGATED_ROUTES] as any)
                            .data.features as AggregatedRouteFeature[]
                    }
                />
                {legendScale ? <HeatmapKey scale={legendScale} /> : null}
            </div>
            <div style={{ display: 'none' }}>{effects.popup}</div>
        </>
    );
};

export default MapRoutes;
