import React, { useState } from 'react';
import cx from 'classnames';
import "/opt/build/repo/src/page-policy/dashboard/row.tsx?resplendence=true";
import { FormattedMessage } from 'react-intl';
import {
    PolicyMetricFragment,
    PolicyDetailsFragment,
    VehicleClass,
    PolicyMetricDataLineFragment,
    PolicyType
} from 'graphql.g';
import Icon, { IconName } from 'common/icon';
import PolicyPerformanceChart from './performance-chart';
import { OperatorName } from 'constants/operators';
import { useHistory, useLocation } from 'react-router';
import { useMapView } from 'common/use-map-view';
import { ComplianceScore } from 'common/compliance-score';
import PolicyRuleTime from 'common/policy-rule-time';

/*
@import 'style.scss';
*/;

const ROW = "rx-page-policy-dashboard-row-1"/*
    @include text-label;
    color: $gray-70;

    &.showingDetails {
        box-shadow: 0rem 8rem 4rem -4rem rgba(0, 0, 0, 0.05);
    }
*/;

const MAIN_ROW = "rx-page-policy-dashboard-row-2"/*
    &:hover {
        cursor: pointer;
    }
*/;

const OPERATOR_ICON_CONTAINER = "rx-page-policy-dashboard-row-3"/*
    &.hovered {
        color: $gray-90;
    }
*/;

const FLEX_ROW = "rx-page-policy-dashboard-row-4"/*
    display: flex;
    flex-direction: row;
    align-items: center;
*/;

const SHOW_DETAILS_ARROW = "rx-page-policy-dashboard-row-5"/*
    height: 32rem;
    width: 32rem;
    &:hover {
        border-radius: 50%;
        background-color: $gray-10;
    }

    &.showingDetails {
        height: 72rem;
        border-radius: 8rem 0 0 0;
        background-color: $gray-10;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4rem;
*/;

const OPERATOR_SUB_ROW = "rx-page-policy-dashboard-row-6"/*
    display: flex;
    justify-content: flex-end;

    &.hovered {
        color: $gray-90;
    }
*/;

const ICON = "rx-page-policy-dashboard-row-7"/*
    height: 32rem;
    width: 32rem;
    padding-right: 8rem;
    display: block;
    margin-top: auto;
    margin-bottom: auto;

    &.hovered {
        fill: $gray-90;
    }
*/;

const AREA_TIME_ROW = "rx-page-policy-dashboard-row-8"/*
    &.hovered {
        color: $gray-90;
    }
*/;

const PERFORMANCE_ROW = "rx-page-policy-dashboard-row-9"/*
    height: 72rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 200rem;
    &.blue {
        color: $blue-50;
    }
*/;
const ICON_FOR_VEHICLE_CLASS: Record<VehicleClass, IconName> = {
    [VehicleClass.bike]: 'Bike',
    [VehicleClass.ebike]: 'eBike',
    [VehicleClass.scooter]: 'Scooter',
    [VehicleClass.moped]: 'Moped',
    [VehicleClass.car]: 'Car',
    [VehicleClass.other]: 'Bike' // we don't have an icon for other right now.
};

type PolicyDetailsWithMetrics = {
    metric: PolicyMetricFragment | null;
} & PolicyDetailsFragment;

interface PolicyDashboardMainRowProps {
    policy: PolicyDetailsWithMetrics;
    hasMultipleOperators: boolean;
    showDetails: boolean;
    setShowDetails: (showDetails: boolean) => void;
    target: number;
}

function PolicyDashboardMainRow({
    policy,
    hasMultipleOperators,
    showDetails,
    setShowDetails,
    target
}: PolicyDashboardMainRowProps) {
    const history = useHistory();
    const searchParams = new URLSearchParams(useLocation().search);
    const mapView = useMapView();
    // we store hovered state in React since we need to have fine control over
    // which elements change text color when the row is hovered
    const [isHovered, setIsHovered] = useState(false);
    // compliance policies SHOULD only have one rule, so we just take the first
    // one.
    const rule = policy.rules[0];

    const summedData = policy.metric?.summedOperatorData;
    // If this policy rule applies to a specific vehicle type we'll show
    // that vehicle icon. if it applies to all vehicles, we don't show an icon
    const icon: IconName | null =
        rule.vehicleClasses.length === 1
            ? ICON_FOR_VEHICLE_CLASS[rule.vehicleClasses[0]]
            : null;

    const navigateToPolicy = () => {
        const dates = searchParams.get('dates');
        // if we have dates selected already, be sure to include them
        const path =
            dates != null
                ? `/${mapView.slug}/policy/${policy.id}?dates=${dates}`
                : `/${mapView.slug}/policy/${policy.id}`;
        history.push(path);
    };

    return (
        <tr
            className={cx(
                ROW,
                MAIN_ROW,
                showDetails && 'showingDetails',
                hasMultipleOperators && 'mainRowWithBorders'
            )}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <td>
                {/* Operator Column */}
                <div
                    className={cx(
                        FLEX_ROW,
                        OPERATOR_ICON_CONTAINER,
                        isHovered && 'hovered'
                    )}
                >
                    {hasMultipleOperators && (
                        <div
                            className={cx(
                                SHOW_DETAILS_ARROW,
                                showDetails && 'showingDetails'
                            )}
                            onClick={() => setShowDetails(!showDetails)}
                        >
                            <Icon icon={showDetails ? 'Up' : 'Down'} />
                        </div>
                    )}
                    <div className={FLEX_ROW} onClick={navigateToPolicy}>
                        {icon && (
                            <Icon
                                icon={icon}
                                className={cx(ICON, isHovered && 'hovered')}
                            />
                        )}
                        {policy.operators.length === 0 ? (
                            <FormattedMessage
                                key="all-operators"
                                defaultMessage="All"
                            />
                        ) : (
                            policy.operators.map(op => op.name).join(', ')
                        )}
                    </div>
                </div>
            </td>
            <td onClick={navigateToPolicy}>
                {/* Area/Time Column */}
                <div className={cx(AREA_TIME_ROW, isHovered && 'hovered')}>
                    {rule.areas.map(a => a.name).join(', ')}
                </div>
                {rule.__typename === 'PolicyMinDeploymentRule' &&
                    rule.startTime != null && (
                        <PolicyRuleTime rule={rule} hover={isHovered} />
                    )}
            </td>
            <td onClick={navigateToPolicy}>
                {/* Performance Column */}
                <div className={cx(PERFORMANCE_ROW, isHovered && 'blue')}>
                    <PolicyPerformanceChart
                        isHovered={isHovered}
                        target={target ?? 0}
                        line={policy.metric?.summedOperatorData}
                    />
                </div>
            </td>
            <td onClick={navigateToPolicy}>
                {/* Compliance Column */}
                {summedData ? (
                    <ComplianceScore
                        summedData={summedData}
                        hover={isHovered}
                    />
                ) : (
                    '--'
                )}
            </td>
        </tr>
    );
}

interface PolicyDashboardSubRowProps {
    line: PolicyMetricDataLineFragment;
    target: number;
    isLast: boolean;
}

function PolicyDashboardSubRow({
    line,
    target,
    isLast
}: PolicyDashboardSubRowProps) {
    // we store hovered state in React since we need to have fine control over
    // which elements change text color when the row is hovered
    const [isHovered, setIsHovered] = useState(false);

    return (
        <tr
            className={cx(ROW, 'subRowWithBorders', isLast && 'lastOfDetails')}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <td>{/* Operator Column */}</td>
            <td>
                {/* Area/Time Column - our current designs call for showing
                the operator name here for sub rows */}
                <div className={cx(OPERATOR_SUB_ROW, isHovered && 'hovered')}>
                    {line.operator?.name ?? null}
                </div>
            </td>
            <td>
                {/* Performance Column */}
                <div className={cx(PERFORMANCE_ROW, isHovered && 'blue')}>
                    {target > 0 ? target : null}
                    <PolicyPerformanceChart
                        isHovered={isHovered}
                        target={target}
                        line={line}
                    />
                </div>
            </td>
            <td>
                {/* Compliance Column */}
                <ComplianceScore summedData={line} hover={isHovered} />
            </td>
        </tr>
    );
}

interface PolicyDashboardRowProps {
    policy: PolicyDetailsWithMetrics;
    operators: Set<OperatorName>;
}

/**
 * Displays a policy row.
 *
 * For policies that apply to one operator, this will be one row.
 * For multi-operator policies, this component will show a summary row that can
 * be expanded to see a row per operator.
 */
function PolicyDashboardRow({ policy, operators }: PolicyDashboardRowProps) {
    // NOTE: due to the constraints of HTML tables, we have to do a few odd
    // things here to align with our designs:
    //  - sub-rows are actually siblings of the original row. We have to just
    //    add more <tr> at the same level and apply styling to give the appearance
    //  - since one row can't contain another, and a <tr> can't have border-radius,
    //    getting a container border appearance is challenging. We selectively
    //    style individual <td> elements to achieve the correct appearance.

    const [showDetails, setShowDetails] = useState(false);
    const hasMultipleOperators =
        policy.metric && policy.metric.data.length > 1 ? true : false;

    // max_idle_time target will differ and the default will always be 0
    const target =
        policy.policyType === PolicyType.max_idle_time
            ? 0
            : policy.metric?.ruleTarget ?? 0;

    return (
        <>
            <PolicyDashboardMainRow
                policy={policy}
                hasMultipleOperators={hasMultipleOperators}
                showDetails={showDetails}
                setShowDetails={setShowDetails}
                target={target}
            />
            {hasMultipleOperators &&
                showDetails &&
                policy.metric?.data
                    // filter out data for operators that exist on the mapview
                    // but are not active in these policies
                    .filter(
                        line =>
                            line.operator &&
                            [...operators].includes(line.operator.name)
                    )
                    .map((line, i, arr) => (
                        <PolicyDashboardSubRow
                            key={line.operator?.name ?? i}
                            line={line}
                            target={target}
                            isLast={i === arr.length - 1}
                        />
                    ))}
        </>
    );
}

export default PolicyDashboardRow;
