/**
 * The following step will outline how to display additional data
 * in routes popups and new layers on the map.
 *
 * Generally we fetch data from an API that returns an array of objects
 * structured like so:
 *
 * [{
 *    rr_geometry_id: string;
 *    count: number;
 *    [key: string]: any;
 * },...]
 *
 * and then turn this data into a GeoJSON Feature Collection which
 * will be added to the map state.
 *
 * The app will then render this layer and display the data in a the popup.
 *
 * Here are the steps you should follow to add a new layer:
 * - Ensure your API is returning data is in the format above
 * - Add a new entry to additionalSegmentDict in src/page-explore/page-heatmaps/routes/additional-segment-overlays.tsx
 * - Add a new layer name to LAYER_NAMES and SOURCE_NAMES in src/page-explore/common/constants.ts
 * - Add a new entry to popupProcessors in src/page-explore/page-heatmaps/routes/popup-processors.tsx
 */

import config from 'config';
import {
    lineString,
    featureCollection,
    FeatureCollection
} from '@turf/helpers';

import { StreetSegments, useStreetSegments } from '../../layers/routes/api';
import { SOURCE_NAMES } from 'page-explore/common/constants';
import { AuthContextType, useAuthInfo } from 'common/authentication';
import { Feature, Geometry } from 'geojson';
import { FeatureFlaggedFeature } from 'graphql.g';
import { useCallback, useEffect, useState } from 'react';
import { useEnabledFeatures } from 'common/use-feature-flags';

type countData = {
    rr_geometry_id: string;
    count: number;
    [key: string]: any;
};

/** Fetches the additional segment data for the given source. */
export const fetchCountsOverlayData = async ({
    endPoint,
    sourceName,
    authInfo,
    streetSegments
}: {
    endPoint: string;
    sourceName: string;
    authInfo: AuthContextType;
    streetSegments: StreetSegments | null;
}) => {
    try {
        const response = await fetch(`${config.apiHost}${endPoint}`, {
            headers: {
                Authorization: `Bearer ${authInfo.token}`
            }
        });
        const data = (await response.json()) as countData[];
        const overlayFeatureCollection = mapCountsOverlayData({
            sourceKey: sourceName,
            countSegments: data,
            streetSegments
        });
        return overlayFeatureCollection;
    } catch (error) {
        throw error;
    }
};

/** Assigns count properties specific to the source */
const mapCountsOverlayData = ({
    sourceKey,
    countSegments,
    streetSegments
}: {
    sourceKey: string;
    countSegments: countData[];
    streetSegments: StreetSegments | null;
}): FeatureCollection => {
    const mappedFeatureCollection: any = [];
    countSegments.forEach(countSegment => {
        const cleanID = countSegment.rr_geometry_id.split('-').join('');
        if (!streetSegments) return; // TODO: handle error
        const segmentMatch = streetSegments[cleanID];
        // Note that not all geoms will have a match
        if (!segmentMatch) return;
        const filteredProps = {
            ...Object.keys(countSegment).reduce((obj, key) => {
                const keyMod = key === 'count' ? `${sourceKey}-count` : key;
                obj[keyMod] = countSegment[key];
                return obj;
            }, {})
        };
        const newLineString = lineString(
            segmentMatch.geometry.coordinates,
            filteredProps
        );
        mappedFeatureCollection.push(newLineString);
    });
    return featureCollection(mappedFeatureCollection);
};

export const findOverLaySegment = (
    segmentID: string,
    featureCollection: FeatureCollection
): Feature<Geometry> | undefined => {
    const features = featureCollection.features;
    if (!features) return;
    const matchingFeature = features.find(feature => {
        const featureID = feature.properties?.rr_geometry_id
            .split('-')
            .join('');
        if (featureID === segmentID) {
            return true;
        }
        return false;
    });
    return matchingFeature as Feature<Geometry> | undefined;
};

type AdditionalOverlay = {
    displayName: string;
    endPoint: string;
    sourceName: string;
    verification: (
        authInfo: AuthContextType,
        enabledFeatures: FeatureFlaggedFeature[]
    ) => boolean;
};

/**
 * More than the traditional definition of overlays, this also
 * contains info on route augmenters. Start here when defining
 * new overlays or adding props to route properties.
 */
export const additionalSegmentList: AdditionalOverlay[] = [
    {
        displayName: 'ATC Counts',
        endPoint: '/custom-dataset/additional-segment-counts',
        sourceName: SOURCE_NAMES.ATC_SEGMENT_COUNTS,
        verification: (authInfo, enabledFeatures) => {
            return enabledFeatures.includes(
                FeatureFlaggedFeature.custom_overlays
            );
        }
    }
];

const fetchOverlays = async ({
    additionalSegmentList,
    authInfo,
    enabledFeatures,
    streetSegments
}) => {
    try {
        const promiseList = additionalSegmentList.map(
            async ({ endPoint, sourceName, verification }) => {
                if (!verification(authInfo, enabledFeatures)) return {};
                return await fetchSingleOverlay({
                    authInfo,
                    endPoint,
                    sourceKey: sourceName,
                    streetSegments
                });
            }
        );
        const overlayFeatureCollections = await Promise.all(promiseList);
        const segments = Object.assign({}, ...overlayFeatureCollections);
        return segments;
    } catch (error) {
        throw error;
    }
};

const fetchSingleOverlay = async ({
    authInfo,
    endPoint,
    sourceKey,
    streetSegments
}) => {
    try {
        const response = await fetch(`${config.apiHost}${endPoint}`, {
            headers: {
                Authorization: `Bearer ${authInfo.token}`
            }
        });
        const countData = await response.json();
        const overlayFeatureCollection = mapCountsOverlayData({
            sourceKey: SOURCE_NAMES.ATC_SEGMENT_COUNTS,
            countSegments: countData,
            streetSegments: streetSegments
        });
        return { [sourceKey]: overlayFeatureCollection };
    } catch (error) {
        throw error;
    }
};

type AdditionalSegmentData = {
    [key: string]: FeatureCollection;
} | null;

type AdditionalSegmentDataHook = () => {
    additionalSegmentData: AdditionalSegmentData;
    additionalSegmentDataLoading: boolean;
    additionalSegmentDataError: Error | null;
};

/**
 * This hook fetches additional segment data for matching segments
 * that have additional layers as defined in `additionalSegmentList`.
 */
export const useAdditionalSegmentData: AdditionalSegmentDataHook = () => {
    const [data, setData] = useState<AdditionalSegmentData>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    const authInfo = useAuthInfo();
    const streetSegments = useStreetSegments();
    const enabledFeatures = useEnabledFeatures();

    const fetchCountsOverlayData = useCallback(async () => {
        if (!streetSegments) {
            setLoading(false);
            setData(null);
            setError(null);
            return;
        }
        setLoading(true);
        try {
            const overlayFeatureCollection = await fetchOverlays({
                additionalSegmentList,
                authInfo,
                enabledFeatures,
                streetSegments
            });
            setData(overlayFeatureCollection);
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    }, [authInfo, enabledFeatures, streetSegments]);

    useEffect(() => {
        fetchCountsOverlayData();
    }, [fetchCountsOverlayData]);

    return {
        additionalSegmentData: data,
        additionalSegmentDataLoading: loading,
        additionalSegmentDataError: error
    };
};
