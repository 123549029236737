export const EXPLORE_URLS = {
    HEATMAPS: 'heatmaps',
    REALTIME: 'realtime'
};
/** Set ids for sources here, and they will be assigned to mapbox layers */
export const SOURCE_NAMES = {
    AGGREGATED_ROUTES: 'aggregated-routes',
    ATC_SEGMENT_COUNTS: 'vivacity-segments-counts-source'
};

/** Set layer names here and they will be used as layer IDs */
export const LAYER_NAMES = {
    ATC_SEGMENT_COUNTS: 'vivacity-segments-counts',
    AGGREGATED_ROUTES: 'routes-aggregated-trip'
};
