import { useMemo } from 'react';
import { useMapView } from './use-map-view';
import { useUser } from './user';

/**
 * A hook for returning features enabled for a given user viewing a mapview.
 */
export function useEnabledFeatures() {
    const { enabledFeatures: userEnabledFeatures } = useUser();
    const { enabledFeatures: mapviewEnabledFeatures } = useMapView();

    return useMemo(() => {
        return userEnabledFeatures.filter(userFeature =>
            mapviewEnabledFeatures.includes(userFeature)
        );
    }, [userEnabledFeatures, mapviewEnabledFeatures]);
}
