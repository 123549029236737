import React from 'react';
import { POPUP_DETAILS } from 'page-explore/layers/routes/popup';
import { FormattedMessage, IntlShape } from 'react-intl';
import { SOURCE_NAMES } from 'page-explore/common/constants';
import { SelectedStreetSegment } from 'page-explore/layers/routes/types';

export const popupProcessors = {
    [SOURCE_NAMES.ATC_SEGMENT_COUNTS]: (
        streetSegment: SelectedStreetSegment,
        matchedSegment: GeoJSON.Feature,
        formatter: IntlShape
    ) => {
        if (!matchedSegment.properties) return <></>;
        const atcProps = matchedSegment.properties;
        const privateTrips =
            atcProps[`${SOURCE_NAMES.ATC_SEGMENT_COUNTS}-count`] -
            streetSegment.properties.count;
        const privatePercentage =
            (privateTrips /
                atcProps[`${SOURCE_NAMES.ATC_SEGMENT_COUNTS}-count`]) *
            100;
        const sharedPercentage =
            (streetSegment.properties.count /
                atcProps[`${SOURCE_NAMES.ATC_SEGMENT_COUNTS}-count`]) *
            100;

        return (
            <>
                <div className={POPUP_DETAILS}>
                    <FormattedMessage
                        key={`{LAYER_NAMES.ATC_SEGMENT_COUNTS}-totalTrips`}
                        defaultMessage="{total} Total Trips"
                        values={{
                            total: formatter.formatNumber(
                                atcProps[
                                    `${SOURCE_NAMES.ATC_SEGMENT_COUNTS}-count`
                                ]
                            )
                        }}
                    />
                </div>
                <div className={POPUP_DETAILS}>
                    <FormattedMessage
                        key={`{LAYER_NAMES.ATC_SEGMENT_COUNTS}-totalTripsPercentPrivate`}
                        defaultMessage="{total} Private Trips ({percentage}%)"
                        values={{
                            total: formatter.formatNumber(privateTrips),
                            percentage: privatePercentage.toFixed(1)
                        }}
                    />
                </div>
                <div className={POPUP_DETAILS}>
                    <FormattedMessage
                        key={`{LAYER_NAMES.ATC_SEGMENT_COUNTS}-totalTripsPercentShared`}
                        defaultMessage="{total} Shared Trips ({percentage}%)"
                        values={{
                            total: formatter.formatNumber(
                                streetSegment.properties.count
                            ),
                            percentage: sharedPercentage.toFixed(1)
                        }}
                    />
                </div>
            </>
        );
    }
};
