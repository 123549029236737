import { SelectedStreetSegment } from 'page-explore/layers/routes/types';
import { RoutesData, StreetSegments } from '../../layers/routes/api';
import { AdditionalSegmentData } from 'page-explore/layers/routes/sources';

export type RoutesActions =
    | {
          type: 'SET_STREET_SEGMENTS';
          payload: {
              streetSegments: StreetSegments | null;
          };
      }
    | {
          type: 'SET_ROUTES_DATA';
          payload: {
              routes: RoutesData | null;
          };
      }
    | {
          type: 'SET_SELECTED_STREET_SEGMENT';
          payload: {
              selectedStreetSegment: SelectedStreetSegment | null;
          };
      }
    | {
          type: 'SET_LOADING';
          payload: { loading: boolean };
      }
    | {
          type: 'SET_ADDITIONAL_SEGMENT_DATA';
          payload: AdditionalSegmentData;
      };

export interface RoutesState {
    streetSegments: StreetSegments;
    routes: RoutesData;
    loading: boolean;
    selectedStreetSegment: SelectedStreetSegment | null;
    additionalSegmentData?: AdditionalSegmentData;
}

export const routesInitialState: RoutesState = {
    streetSegments: {},
    routes: {
        referenceCounts: {},
        totalTripCount: 0,
        matchedTripCount: 0
    },
    loading: false,
    selectedStreetSegment: null
};

export function routesReducer(
    state: Readonly<RoutesState>,
    action: RoutesActions
): Readonly<RoutesState> {
    switch (action.type) {
        case 'SET_STREET_SEGMENTS':
            return {
                ...state,
                streetSegments: action.payload.streetSegments ?? {}
            };
        case 'SET_ROUTES_DATA':
            return {
                ...state,
                routes: action.payload.routes ?? {
                    referenceCounts: {},
                    totalTripCount: 0,
                    matchedTripCount: 0
                }
            };
        case 'SET_SELECTED_STREET_SEGMENT':
            return {
                ...state,
                selectedStreetSegment: action.payload.selectedStreetSegment
            };
        case 'SET_LOADING': {
            return {
                ...state,
                loading: action.payload.loading
            };
        }
        case 'SET_ADDITIONAL_SEGMENT_DATA': {
            return {
                ...state,
                additionalSegmentData: state.additionalSegmentData
                    ? { ...state.additionalSegmentData, ...action.payload }
                    : action.payload
            };
        }
        default:
            return state;
    }
}
