import { defineMessages, MessageDescriptor } from 'react-intl';

const m = defineMessages({
    'active-vehicles': 'Active Vehicles',
    'active-vehicles-description':
        'The number of vehicles available for rent on the public right of way from the most recent scan. Does not include vehicles in a trip.',
    'max-active': 'Max Active',
    'max-active-description':
        'The max amount of concurrent active vehicles seen on the public right of way since 4:00AM. This stat resets at 11:59 PM daily.',
    'morning-deployed': 'Morning Deployed',
    'morning-deployed-description':
        'The max amount of active vehicles seen on the public right of way concurrently between 4:00AM and 8:00 AM.',
    'max-available': 'Max Available',
    'max-available-description':
        'The maximum number of vehicles concurrently available for rent.',
    'max-available-average': 'Max Available Average',
    'max-available-average-description':
        'The average maximum number of vehicles concurrently available for rent per day.',
    'max-parked': 'Max Parked',
    'max-parked-description':
        'The maximum number of concurrent vehicles that are either available or unavailable.',
    'max-parked-average': 'Max Parked Average',
    'max-parked-average-description':
        'The average maximum number of concurrent vehicles that are either available or unavailable per day.',
    'max-unavailable': 'Max Unavailable',
    'max-unavailable-description':
        'The maximum number of vehicles concurrently unavailable for rent but still on the street. Vehicles could be unavailable for rent due to low battery, maintenance needs, or other reasons.',
    'max-unavailable-average': 'Max Unavailable Average',
    'max-unavailable-average-description':
        'The average maximum number of vehicles concurrently unavailable for rent but still on the street per day. Vehicles could be unavailable for rent due to low battery, maintenance needs, or other reasons.',
    'start-trips': 'Start Trips',
    'start-trips-description':
        'The total number of trips that started in the service area.',
    'start-trips-area-description':
        'The total number of trips that started in this area of interest.',
    'end-trips': 'End Trips',
    'end-trips-description':
        'The total number of trips that ended in the service area.',
    'end-trips-area-description':
        'The total number of trips that ended in this area of interest.',
    'yesterday-start-trips': 'Yesterday Trips',
    'yesterday-start-trips-description':
        'The total number of trips that started in the service area yesterday.',
    'trips-per-vehicle': 'Trips Per Vehicle',
    'trips-per-vehicle-description':
        'The total number of trips divided by the max available count',
    'aggregated-trips-per-vehicle-description':
        'The total number of trips divided by the sum of the daily max available counts. Number reflects the average daily trips per vehicle.',
    'mds-morning-deployed': 'Morning Deployment',
    'mds-morning-deployed-description':
        'The number of available vehicles at 8:00 AM.',
    'mds-morning-deployed-average': 'Morning Deployment',
    'mds-morning-deployed-average-description':
        'The average number of available vehicles at 8:00 AM each day.',
    scooter: 'Scooters',
    'scooter-mode-shift-percentage-description':
        'Percentage of scooter trips that replaced a high carbon mode',
    ebike: 'E-bikes',
    'ebike-mode-shift-percentage-description':
        'Percentage of e-bike trips that replaced a high carbon mode',
    bike: 'Bike',
    'bike-mode-shift-percentage-description':
        'Percentage of bike trips that replaced a high carbon mode',
    other: 'Other',
    'other-mode-shift-percentage-description':
        'Percentage of other shared micromobility trips that replaced a high carbon mode',
    moped: 'Moped',
    'moped-mode-shift-percentage-description':
        'Percentage of moped trips that replaced a high carbon mode'
});

export type HeaderInfo = {
    name: MessageDescriptor;
    description: MessageDescriptor;
};

const descriptions = {
    activeVehicles: {
        name: m['active-vehicles'],
        description: m['active-vehicles-description']
    },
    maxVehicles: {
        name: m['max-active'],
        description: m['max-active-description']
    },
    morningDeployed: {
        name: m['morning-deployed'],
        description: m['morning-deployed-description']
    },
    maxAvailable: {
        name: m['max-available'],
        description: m['max-available-description']
    },
    maxParked: {
        name: m['max-parked'],
        description: m['max-parked-description']
    },
    maxUnavailable: {
        name: m['max-unavailable'],
        description: m['max-unavailable-description']
    },
    startTrips: {
        name: m['start-trips'],
        description: m['start-trips-description']
    },
    startTripsArea: {
        name: m['start-trips'],
        description: m['start-trips-area-description']
    },
    endTrips: {
        name: m['end-trips'],
        description: m['end-trips-description']
    },
    endTripsAreas: {
        name: m['end-trips'],
        description: m['end-trips-area-description']
    },
    yesterdayStartTrips: {
        name: m['yesterday-start-trips'],
        description: m['yesterday-start-trips-description']
    },
    tripsPerVehicle: {
        name: m['trips-per-vehicle'],
        description: m['trips-per-vehicle-description']
    },
    mdsMorningDeployed: {
        name: m['mds-morning-deployed'],
        description: m['mds-morning-deployed-description']
    }
};

export const aggregatedDescriptions = {
    maxAvailable: {
        name: m['max-available-average'],
        description: m['max-available-average-description']
    },
    maxParked: {
        name: m['max-parked-average'],
        description: m['max-parked-average-description']
    },
    maxUnavailable: {
        name: m['max-unavailable-average'],
        description: m['max-unavailable-average-description']
    },
    startTrips: {
        name: m['start-trips'],
        description: m['start-trips-description']
    },
    startTripsArea: {
        name: m['start-trips'],
        description: m['start-trips-area-description']
    },
    endTrips: {
        name: m['end-trips'],
        description: m['end-trips-description']
    },
    endTripsAreas: {
        name: m['end-trips'],
        description: m['end-trips-area-description']
    },
    tripsPerVehicle: {
        name: m['trips-per-vehicle'],
        description: m['aggregated-trips-per-vehicle-description']
    },
    morningDeployed: {
        name: m['mds-morning-deployed-average'],
        description: m['mds-morning-deployed-average-description']
    },
    scooter: {
        name: m['scooter'],
        description: m['scooter-mode-shift-percentage-description']
    },
    ebike: {
        name: m['ebike'],
        description: m['ebike-mode-shift-percentage-description']
    },
    bike: {
        name: m['bike'],
        description: m['bike-mode-shift-percentage-description']
    },
    other: {
        name: m['other'],
        description: m['other-mode-shift-percentage-description']
    },
    moped: {
        name: m['moped'],
        description: m['moped-mode-shift-percentage-description']
    }
};

export default descriptions;
