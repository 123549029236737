import React from 'react';
import "/opt/build/repo/src/common/vehicle-classes.tsx?resplendence=true";
import { useMapView } from 'common/use-map-view';
import useData from 'utils/use-data';
import { gql } from '@apollo/client';
import {
    MapViewVehicleClassesData,
    MapViewVehicleClassesArgs,
    OperatorVehicleClassesData,
    OperatorVehicleClassesArgs,
    VehicleClass
} from 'graphql.g';
import { useQueryState } from 'router';
import { Select } from 'common/select';
import {
    components as reactSelectComponents,
    OptionProps,
    SingleValueProps
} from 'react-select';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import * as strings from 'strings';
import Icon, { IconName } from 'common/icon';
import { orderBy } from 'lodash';

/*
@import 'style';
*/;

const MAPVIEW_QUERY = gql`
    query MapViewVehicleClasses($slug: String!) {
        mapView(slug: $slug) {
            id
            availableVehicleClasses
        }
    }
`;
const OPERATOR_QUERY = gql`
    query OperatorVehicleClasses($slug: String!, $operator: ID!) {
        mapView(slug: $slug) {
            id
            operator(id: $operator) {
                id
                availableVehicleClasses
            }
        }
    }
`;

export function useVehicleClasses(operatorId?: string) {
    const { slug } = useMapView();
    const intl = useIntl();

    const mapViewData = useData<
        MapViewVehicleClassesData,
        MapViewVehicleClassesArgs
    >(MAPVIEW_QUERY, { slug });

    const operatorData = useData<
        OperatorVehicleClassesData,
        OperatorVehicleClassesArgs
    >(OPERATOR_QUERY, operatorId ? { slug, operator: operatorId } : 'skip');

    if (operatorId) {
        if (!operatorData) return null;
        return orderBy(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            operatorData?.mapView.operator!.availableVehicleClasses ?? [],
            vc => intl.formatMessage(strings.vehicleClasses[vc])
        );
    } else {
        if (!mapViewData) return null;
        return orderBy(mapViewData?.mapView.availableVehicleClasses ?? [], vc =>
            intl.formatMessage(strings.vehicleClasses[vc])
        );
    }
}

const messages = defineMessages({
    'all-vehicles-option': 'All vehicles ({count})'
});

export const ICON_FOR_VEHICLE_CLASS: { [key in VehicleClass]: IconName } = {
    [VehicleClass.bike]: 'Bike',
    [VehicleClass.car]: 'Car',
    [VehicleClass.ebike]: 'eBike',
    [VehicleClass.moped]: 'Moped',
    [VehicleClass.scooter]: 'Scooter',
    [VehicleClass.other]: 'Bike' // we don't have an icon for other right now.
};

export function useVehicleClass(searchParam: string = 'vehicleClass') {
    const [vehicleClass] = useQueryState<VehicleClass | null>(searchParam, {
        constrain: {
            options: new Set([null, ...Object.values(VehicleClass)]),
            fallbackValue: null
        }
    });
    return vehicleClass;
}

const getVehicleClassOption = (intl: IntlShape) => (
    vc: VehicleClass
): VehicleClassOption => {
    return {
        value: vc,
        label: intl.formatMessage(strings.vehicleClasses[vc]),
        icon: ICON_FOR_VEHICLE_CLASS[vc]
    };
};

interface VehicleClassPickerProps {
    vehicleClasses: VehicleClass[];
    onChange: (vc: VehicleClass | null) => void;
    value: VehicleClass | null;
}

/** A vehicle class selector that requires the state to be manage by the component
 * rendering this selector.
 */
export function VehicleClassPicker({
    vehicleClasses,
    onChange,
    value
}: VehicleClassPickerProps) {
    const intl = useIntl();

    const allVehiclesOption = {
        value: null,
        label: intl.formatMessage(messages['all-vehicles-option'], {
            count: vehicleClasses.length
        }),
        icon: null
    };

    const vehicleClassOptions = [
        allVehiclesOption,
        ...vehicleClasses.map(getVehicleClassOption(intl))
    ];

    return (
        <Select
            onChange={option => onChange(option?.value ?? null)}
            value={
                value != null
                    ? getVehicleClassOption(intl)(value)
                    : allVehiclesOption
            }
            options={vehicleClassOptions}
            components={{
                Option: OptionWithIcon,
                SingleValue: SingleValueWithIcon
            }}
            styles={{
                option: (base, { isDisabled }) => ({
                    ...base,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    cursor: isDisabled ? 'default' : 'pointer'
                }),
                singleValue: base => ({
                    ...base,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                })
            }}
        />
    );
}

interface VehicleClassQueryStatePickerProps {
    vehicleClasses: VehicleClass[];
    searchParam?: string;
}

/** A vehicle class selector that stores it's state in the query params */
export function VehicleClassQueryStatePicker({
    vehicleClasses,
    searchParam = 'vehicleClass'
}: VehicleClassQueryStatePickerProps) {
    const [vehicleClass, setVehicleClass] = useQueryState(searchParam, {
        constrain: {
            options: new Set([...vehicleClasses, null]),
            fallbackValue: null
        }
    });

    return (
        <VehicleClassPicker
            vehicleClasses={vehicleClasses}
            onChange={setVehicleClass}
            value={vehicleClass}
        />
    );
}

interface VehicleClassOption {
    value: VehicleClass | null;
    label: string;
    icon: IconName | null;
}

const ICON = "rx-common-vehicle-classes-1"/*
    margin-right: 8rem;
*/;

function OptionWithIcon(props: OptionProps<VehicleClassOption, false>) {
    return (
        <reactSelectComponents.Option {...props}>
            {props.data.icon && (
                <Icon icon={props.data.icon} className={ICON} />
            )}
            {props.data.label}
        </reactSelectComponents.Option>
    );
}

function SingleValueWithIcon(
    props: SingleValueProps<VehicleClassOption, false>
) {
    return (
        <reactSelectComponents.SingleValue {...props}>
            {props.data.icon && (
                <Icon icon={props.data.icon} className={ICON} />
            )}
            {props.data.label}
        </reactSelectComponents.SingleValue>
    );
}
