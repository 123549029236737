import {
    MapViewOverviewData,
    CurrentUserData,
    MapViewOperatorFragment,
    MapViewOperatorInfoFragment
} from 'graphql.g';
import { OperatorName } from 'constants/operators';

export enum VehicleType {
    Unknown = 0,
    Bike = 1,
    eBike,
    Scooter,
    eScooter,
    Moped,
    Car
}

export enum FeedType {
    Unknown = 0,
    Gbfs,
    Mds,
    Scraper,
    Unofficial
}

export const VEHICLE_STATUS_DESCRIPTIONS = {
    available: {
        description: 'Vehicles that are currently available for rent.'
    },
    unavailable: {
        description:
            'Vehicles that are unavailable for rent but that are still parked on the street. Only provided for official MDS feeds.'
    },
    reserved: {
        description:
            'Vehicles that are unavailable for rent because someone has reserved it for an upcoming trip.'
    }
};
export type VehicleStatus = keyof typeof VEHICLE_STATUS_DESCRIPTIONS;
export const VEHICLE_STATUS_NAMES: VehicleStatus[] = Object.keys(
    VEHICLE_STATUS_DESCRIPTIONS
) as VehicleStatus[];

export type VehicleStatusReason =
    // MDS 0 and MDS 1
    | 'agency_drop_off'
    | 'maintenance'
    // MDS 0 only
    | 'maintenance_drop_off'
    | 'rebalance_drop_off'
    | 'service_start'
    | 'user_drop_off'
    | 'low_battery'
    // MDS 1 only
    | 'provider_drop_off'
    | 'trip_end'
    | 'battery_charged'
    | 'battery_low'
    | 'comms_restored'
    | 'agency_pick_up'
    | 'comms_lost'
    | 'compliance_pick_up'
    | 'decommissioned'
    | 'located'
    | 'maintenance_pick_up'
    | 'missing'
    | 'off_hours'
    | 'on_hours'
    | 'rebalance_pick_up'
    | 'reservation_cancel'
    | 'reservation_start'
    | 'system_resume'
    | 'system_suspend'
    | 'trip_cancel'
    | 'trip_enter_jurisdiction'
    | 'trip_leave_jurisdiction'
    | 'trip_start'
    | 'unspecified';

export const VEHICLE_STATUS_REASONS: VehicleStatusReason[] = [
    'agency_drop_off',
    'agency_pick_up',
    'battery_charged',
    'battery_low',
    'comms_lost',
    'comms_restored',
    'compliance_pick_up',
    'decommissioned',
    'located',
    'low_battery',
    'maintenance',
    'maintenance_drop_off',
    'maintenance_pick_up',
    'missing',
    'off_hours',
    'on_hours',
    'provider_drop_off',
    'rebalance_drop_off',
    'rebalance_pick_up',
    'reservation_cancel',
    'reservation_start',
    'service_start',
    'system_resume',
    'system_suspend',
    'trip_cancel',
    'trip_end',
    'trip_enter_jurisdiction',
    'trip_leave_jurisdiction',
    'trip_start',
    'unspecified',
    'user_drop_off'
];

export type MapViewData<T> = {
    mapView: T;
};
export type OperatorData<T> = MapViewData<{
    operator: T;
}>;
export type OperatorsData<T> = MapViewData<{ operators: T[] }>;

type OperatorInfo = {
    id: string;
    name: OperatorName;
};

interface BaseOrganization {
    id: string;
    name: string;
    locale: string;
    mapViews: {
        slug: string;
        name: string;
        hasAtLeastOneMdsFeed: boolean;
    }[];
}

interface OperatorOrg extends BaseOrganization {
    __typename: 'OperatorOrganization';
    operatorInfo: OperatorInfo;
}

interface AgencyOrg extends BaseOrganization {
    __typename: 'AgencyOrganization';
}

export type Organization = OperatorOrg | AgencyOrg;

export type User = Omit<CurrentUserData['user'], 'orgAuth'> & {
    useDemoFeatures: boolean;
    setUseDemoFeatures: (value: boolean) => void;
    orgAuth: NonNullable<CurrentUserData['user']['orgAuth']>;
};

export type MapViewOperator = MapViewOperatorFragment;

type MapViewOverView = NonNullable<MapViewOverviewData['mapView']>;
export type MapView = Omit<MapViewOverView, 'operators'> & {
    operators: MapViewOperator[];
    currency: 'USD';
    activeOperators: MapViewOperatorInfoFragment[];
};

export type AreaOfInterest = {
    id: string;
    name: string;
    priority: number;
    operatorIds: string[];
    tableViewOnly: boolean;
};

// NOTE: The GeoJSON types below are not all-inclusive. They may need to be
// altered or extended and there's many more types not included here.
// If we find ourselves doing a lot more GeoJSON work on the frontend, we may
// want to use a library like turf.js instead of rolling our own types.

export interface GeoJSONLineString {
    type: 'LineString';
    coordinates: number[][];
}

export interface GeoJSONFeature<T> {
    type: 'Feature';
    geometry: T;
    properties: Record<string, any>;
}

export interface GeoJSONFeatureCollection<T> {
    type: 'FeatureCollection';
    features: GeoJSONFeature<T>[];
}
