import { OperatorName } from 'constants/operators';

// @ts-ignore (require.context is a webpack-specific method)
const reqContextOperatorLogosMonoSvgs = require.context(
    '../assets/operator-logos-mono',
    true,
    /\.svg$/
);

// @ts-ignore (require.context is a webpack-specific method)
const reqVehicleImageSvg = require.context(
    '../assets/vehicles',
    true,
    /\.svg$/
);

export const getBikeSvgForOperator = (operatorName: OperatorName): string => {
    return (
        reqVehicleImageSvg(`./bike-${operatorName.toLowerCase()}.svg`) ??
        require('../assets/vehicles/bike-generic.svg')
    );
};

export const getScooterSvgForOperator = (
    operatorName: OperatorName
): string => {
    return (
        reqVehicleImageSvg(`./scooter-${operatorName.toLowerCase()}.svg`) ??
        require('../assets/vehicles/scooter-generic.svg')
    );
};

export const getCarSvgForOperator = (operatorName: OperatorName): string => {
    return (
        reqVehicleImageSvg(`./car-${operatorName.toLowerCase()}.svg`) ??
        require('../assets/vehicles/car-generic.svg')
    );
};

export const getLogoSvgForOperator = (
    operatorName: OperatorName
): string | null => {
    try {
        return reqContextOperatorLogosMonoSvgs(
            './' + operatorName.toLowerCase() + '.svg'
        );
    } catch {
        return null;
    }
};
