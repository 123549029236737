import { Expression, Layer, Sources } from 'mapbox-gl';
import { HEX_COLORS } from 'page-explore/page-heatmaps/hex-bins';
import { useMemo } from 'react';
import { flatMap } from 'utils/helpers';
import { DataStep } from './data-steps';
import { SelectedStreetSegment } from './types';
import { colorArrayToRGB } from 'common/color-array-to-rgb';
import { LAYER_NAMES, SOURCE_NAMES } from 'page-explore/common/constants';

const DEFAULT_COLOR_STEPS: Expression = [
    'step',
    ['get', 'percentage'],
    colorArrayToRGB(HEX_COLORS[0]),
    0.1,
    colorArrayToRGB(HEX_COLORS[1]),
    0.5,
    colorArrayToRGB(HEX_COLORS[2]),
    1,
    colorArrayToRGB(HEX_COLORS[3]),
    2,
    colorArrayToRGB(HEX_COLORS[4]),
    6,
    colorArrayToRGB(HEX_COLORS[5]),
    15,
    colorArrayToRGB(HEX_COLORS[6])
];

export const DEFAULT_WIDTH_STEPS: Expression = [
    'interpolate',
    ['linear'],
    ['zoom'],
    12, // at zoom level 12
    1.5, // make the line 1.5 units wide
    22, // at zoom level 22 (max)
    12 // make the line 12 units wide
];

/** starting pixel width for each zoom level */
const BASE_WIDTH = {
    small: 1.5,
    medium: 3,
    large: 11
};
/** width multiplier for each zoom level */
const MULTIPLIER = {
    small: 0.1,
    medium: 0.5,
    large: 1.8
};

type LayerGenerator = (
    dataStep?: DataStep[] | null,
    selectedStreetSegment?: SelectedStreetSegment | null
) => Layer;

const layerGenerators: { [any: string]: LayerGenerator } = {
    [SOURCE_NAMES.AGGREGATED_ROUTES]: (
        dataSteps: DataStep[] | null,
        selectedStreetSegment: SelectedStreetSegment | null
    ) => ({
        id: LAYER_NAMES.AGGREGATED_ROUTES,
        type: 'line',
        source: SOURCE_NAMES.AGGREGATED_ROUTES,
        layout: {
            'line-join': 'round',
            'line-cap': 'round'
        },
        paint: {
            'line-opacity': 0.85,
            'line-color': getLineColorSteps(dataSteps, selectedStreetSegment),
            'line-width': getLineWidthSteps(dataSteps)
        }
    }),
    [SOURCE_NAMES.ATC_SEGMENT_COUNTS]: () => ({
        id: LAYER_NAMES.ATC_SEGMENT_COUNTS,
        type: 'line',
        source: SOURCE_NAMES.ATC_SEGMENT_COUNTS,
        paint: {
            'line-color': 'green',
            'line-width': 5
        }
    })
};

export function useRoutesLayers(
    sources: Sources,
    dataSteps: DataStep[] | null,
    selectedStreetSegment: SelectedStreetSegment | null
) {
    return useMemo(() => {
        let layers: Layer[] = [];

        Object.keys(sources).forEach(sourceKey => {
            const generator = layerGenerators[sourceKey];
            if (generator) {
                layers.push(generator(dataSteps, selectedStreetSegment));
            }
        });

        return layers;
    }, [sources, dataSteps, selectedStreetSegment]);
}

const getLineColorSteps = (
    dataSteps: DataStep[] | null,
    selectedSegment: SelectedStreetSegment | null
): Expression => {
    const steps =
        dataSteps == null
            ? DEFAULT_COLOR_STEPS
            : [
                  'step',
                  ['get', 'count'],
                  colorArrayToRGB(HEX_COLORS[0]),
                  ...flatMap(dataSteps, ({ value, color }) => [value, color])
              ];

    // stepped colors
    return [
        // if we have a selected segment, use this color
        'match',
        ['get', 'id'],
        selectedSegment?.properties.id ?? '',
        '#c0c5ca',
        // otherwise use our stepped colors
        steps
    ];
};

const getLineWidthSteps = (dataSteps: DataStep[] | null): Expression => {
    if (dataSteps == null) {
        return DEFAULT_WIDTH_STEPS;
    }

    // 🤮 I'm sorry this is so hard to read
    // The basic idea is to give MapBox an interpolate Expression that it can
    // use to set the line width accounting for both zoom level AND trip counts
    //
    // For each zoom level, we provide a line width, or a second
    // expression that defines the line width based on the 'count'.
    //
    // We use a MULTIPLIER for each level so that we can normalize the range
    // of widths for different zoom levels. The formula is:
    //     widths.map(i => i * MULTIPLIER + BASE_WIDTH)
    //     OR
    //     [1, 2, 3, 4, 5, 6].map(i => i * 1.8 + 11)
    //
    // For example, given a range of widths = [1, 2, 3, 4, 5, 6]:
    //
    // Adding each width to a BASE_WIDTH of 1 pixel is a drastic difference. We
    // would get [2, 3, 4, 5, 6, 7].
    // Since we want these values to stay small, this is too large of a range.
    // By using the small MULTIPLIER, we get a
    // new range of [ 1.1, 1.2, 1.3, 1.4, 1.5, 1.6 ]
    //
    // Likewise, if now the BASE_WIDTH is 11, because we zoomed in on the map,
    // we can use a large MULTIPLIER of 1.8. Now our new range is
    // [12.8, 14.6, 16.4, 18.2, 20, 21.8]
    return [
        'interpolate',
        ['linear'],
        ['zoom'],
        10, // at zoom level 10 or less
        1, // make everything 1rem
        12, // at zoom level 12
        [
            // use stepped width values based on the 'count' from here on
            'step',
            ['get', 'count'],
            BASE_WIDTH.small,
            ...flatMap(dataSteps, ({ value, width }) => [
                value,
                width * MULTIPLIER.small + BASE_WIDTH.small
            ])
        ],
        15, // at zoom level 15
        [
            'step',
            ['get', 'count'],
            3,
            ...flatMap(dataSteps, ({ value, width }) => [
                value,
                width * MULTIPLIER.medium + BASE_WIDTH.medium
            ])
        ],
        22, // at zoom level 22
        [
            'step',
            ['get', 'count'],
            11,
            ...flatMap(dataSteps, ({ value, width }) => [
                value,
                width * MULTIPLIER.large + BASE_WIDTH.large
            ])
        ]
    ];
};
